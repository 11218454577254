
        <template>
          <div class="vc-snippet-doc">
            <h2>Upload 上传</h2>
<p>通过点击或者拖拽上传文件</p>
<h3>点击上传</h3>
<vc-snippet>
                  <div slot="desc"><p>通过 slot 你可以传入自定义的上传按钮类型和文字提示。可通过设置<code>limit</code>和<code>on-exceed</code>来限制上传文件的个数和定义超出限制时的行为。可通过设置<code>before-remove</code>来阻止文件移除操作。</p>
</div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-upload</span>
    <span class="hljs-attr">class</span>=<span class="hljs-string">"upload-demo"</span>
    <span class="hljs-attr">action</span>=<span class="hljs-string">"https://jsonplaceixu-upload--elholder.typicode.com/posts/"</span>
    <span class="hljs-attr">:on-preview</span>=<span class="hljs-string">"handlePreview"</span>
    <span class="hljs-attr">:on-remove</span>=<span class="hljs-string">"handleRemove"</span>
    <span class="hljs-attr">:before-remove</span>=<span class="hljs-string">"beforeRemove"</span>
    <span class="hljs-attr">multiple</span>
    <span class="hljs-attr">:limit</span>=<span class="hljs-string">"3"</span>
    <span class="hljs-attr">:on-exceed</span>=<span class="hljs-string">"handleExceed"</span>
    <span class="hljs-attr">:file-list</span>=<span class="hljs-string">"fileList"</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"small"</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span>&gt;</span>点击上传<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">slot</span>=<span class="hljs-string">"tip"</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-upload__tip"</span>&gt;</span>只能上传jpg/png文件，且不超过500kb<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">ixu-upload</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">fileList</span>: [{<span class="hljs-attr">name</span>: <span class="hljs-string">'food.jpeg'</span>, <span class="hljs-attr">url</span>: <span class="hljs-string">'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'</span>}, {<span class="hljs-attr">name</span>: <span class="hljs-string">'food2.jpeg'</span>, <span class="hljs-attr">url</span>: <span class="hljs-string">'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'</span>}]
      };
    },
    <span class="hljs-attr">methods</span>: {
      handleRemove(file, fileList) {
        <span class="hljs-built_in">console</span>.log(file, fileList);
      },
      handlePreview(file) {
        <span class="hljs-built_in">console</span>.log(file);
      },
      handleExceed(files, fileList) {
        <span class="hljs-keyword">this</span>.$message.warning(<span class="hljs-string">`当前限制选择 3 个文件，本次选择了 <span class="hljs-subst">${files.length}</span> 个文件，共选择了 <span class="hljs-subst">${files.length + fileList.length}</span> 个文件`</span>);
      },
      beforeRemove(file, fileList) {
        <span class="hljs-keyword">return</span> <span class="hljs-keyword">this</span>.$confirm(<span class="hljs-string">`确定移除 <span class="hljs-subst">${ file.name }</span>？`</span>);
      }
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>用户头像上传</h3>
<p>使用 <code>before-upload</code> 限制用户上传的图片格式和大小。</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-1 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-upload</span>
    <span class="hljs-attr">class</span>=<span class="hljs-string">"avatar-uploader"</span>
    <span class="hljs-attr">action</span>=<span class="hljs-string">"https://jsonplaceholder.typicode.com/posts/"</span>
    <span class="hljs-attr">:show-file-list</span>=<span class="hljs-string">"false"</span>
    <span class="hljs-attr">:on-success</span>=<span class="hljs-string">"handleAvatarSuccess"</span>
    <span class="hljs-attr">:before-upload</span>=<span class="hljs-string">"beforeAvatarUpload"</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">img</span> <span class="hljs-attr">v-if</span>=<span class="hljs-string">"imageUrl"</span> <span class="hljs-attr">:src</span>=<span class="hljs-string">"imageUrl"</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"avatar"</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">i</span> <span class="hljs-attr">v-else</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-icon-plus avatar-uploader-icon"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">i</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">ixu-upload</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">style</span>&gt;</span><span class="css">
  <span class="hljs-selector-class">.avatar-uploader</span> <span class="hljs-selector-class">.el-upload</span> {
    <span class="hljs-attribute">border</span>: <span class="hljs-number">1px</span> dashed <span class="hljs-number">#d9d9d9</span>;
    <span class="hljs-attribute">border-radius</span>: <span class="hljs-number">6px</span>;
    <span class="hljs-attribute">cursor</span>: pointer;
    <span class="hljs-attribute">position</span>: relative;
    <span class="hljs-attribute">overflow</span>: hidden;
  }
  <span class="hljs-selector-class">.avatar-uploader</span> <span class="hljs-selector-class">.el-upload</span><span class="hljs-selector-pseudo">:hover</span> {
    <span class="hljs-attribute">border-color</span>: <span class="hljs-number">#409EFF</span>;
  }
  <span class="hljs-selector-class">.avatar-uploader-icon</span> {
    <span class="hljs-attribute">font-size</span>: <span class="hljs-number">28px</span>;
    <span class="hljs-attribute">color</span>: <span class="hljs-number">#8c939d</span>;
    <span class="hljs-attribute">width</span>: <span class="hljs-number">178px</span>;
    <span class="hljs-attribute">height</span>: <span class="hljs-number">178px</span>;
    <span class="hljs-attribute">line-height</span>: <span class="hljs-number">178px</span>;
    <span class="hljs-attribute">text-align</span>: center;
  }
  <span class="hljs-selector-class">.avatar</span> {
    <span class="hljs-attribute">width</span>: <span class="hljs-number">178px</span>;
    <span class="hljs-attribute">height</span>: <span class="hljs-number">178px</span>;
    <span class="hljs-attribute">display</span>: block;
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">style</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">imageUrl</span>: <span class="hljs-string">''</span>
      };
    },
    <span class="hljs-attr">methods</span>: {
      handleAvatarSuccess(res, file) {
        <span class="hljs-keyword">this</span>.imageUrl = URL.createObjectURL(file.raw);
      },
      beforeAvatarUpload(file) {
        <span class="hljs-keyword">const</span> isJPG = file.type === <span class="hljs-string">'image/jpeg'</span>;
        <span class="hljs-keyword">const</span> isLt2M = file.size / <span class="hljs-number">1024</span> / <span class="hljs-number">1024</span> &lt; <span class="hljs-number">2</span>;

        <span class="hljs-keyword">if</span> (!isJPG) {
          <span class="hljs-keyword">this</span>.$message.error(<span class="hljs-string">'上传头像图片只能是 JPG 格式!'</span>);
        }
        <span class="hljs-keyword">if</span> (!isLt2M) {
          <span class="hljs-keyword">this</span>.$message.error(<span class="hljs-string">'上传头像图片大小不能超过 2MB!'</span>);
        }
        <span class="hljs-keyword">return</span> isJPG &amp;&amp; isLt2M;
      }
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>照片墙</h3>
<p>使用 <code>list-type</code> 属性来设置文件列表的样式。</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-2 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-upload</span>
    <span class="hljs-attr">action</span>=<span class="hljs-string">"https://jsonplaceholder.typicode.com/posts/"</span>
    <span class="hljs-attr">list-type</span>=<span class="hljs-string">"picture-card"</span>
    <span class="hljs-attr">:on-preview</span>=<span class="hljs-string">"handlePictureCardPreview"</span>
    <span class="hljs-attr">:on-remove</span>=<span class="hljs-string">"handleRemove"</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">i</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-icon-plus"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">i</span>&gt;</span>
  <span class="hljs-tag">&lt;/<span class="hljs-name">ixu-upload</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">el-dialog</span> <span class="hljs-attr">:visible.sync</span>=<span class="hljs-string">"dialogVisible"</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">img</span> <span class="hljs-attr">width</span>=<span class="hljs-string">"100%"</span> <span class="hljs-attr">:src</span>=<span class="hljs-string">"dialogImageUrl"</span> <span class="hljs-attr">alt</span>=<span class="hljs-string">""</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">el-dialog</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">dialogImageUrl</span>: <span class="hljs-string">''</span>,
        <span class="hljs-attr">dialogVisible</span>: <span class="hljs-literal">false</span>
      };
    },
    <span class="hljs-attr">methods</span>: {
      handleRemove(file, fileList) {
        <span class="hljs-built_in">console</span>.log(file, fileList);
      },
      handlePictureCardPreview(file) {
        <span class="hljs-keyword">this</span>.dialogImageUrl = file.url;
        <span class="hljs-keyword">this</span>.dialogVisible = <span class="hljs-literal">true</span>;
      }
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>文件缩略图</h3>
<p>使用 <code>scoped-slot</code> 去设置缩略图模版。</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-3 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">ixu-upload</span>
  <span class="hljs-attr">action</span>=<span class="hljs-string">"#"</span>
  <span class="hljs-attr">list-type</span>=<span class="hljs-string">"picture-card"</span>
  <span class="hljs-attr">:auto-upload</span>=<span class="hljs-string">"false"</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">i</span> <span class="hljs-attr">slot</span>=<span class="hljs-string">"default"</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-icon-plus"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">i</span>&gt;</span>
    <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">slot</span>=<span class="hljs-string">"file"</span> <span class="hljs-attr">slot-scope</span>=<span class="hljs-string">"{file}"</span>&gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">img</span>
        <span class="hljs-attr">class</span>=<span class="hljs-string">"el-upload-list__item-thumbnail"</span>
        <span class="hljs-attr">:src</span>=<span class="hljs-string">"file.url"</span> <span class="hljs-attr">alt</span>=<span class="hljs-string">""</span>
      &gt;</span>
      <span class="hljs-tag">&lt;<span class="hljs-name">span</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-upload-list__item-actions"</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">span</span>
          <span class="hljs-attr">class</span>=<span class="hljs-string">"el-upload-list__item-preview"</span>
          @<span class="hljs-attr">click</span>=<span class="hljs-string">"handlePictureCardPreview(file)"</span>
        &gt;</span>
          <span class="hljs-tag">&lt;<span class="hljs-name">i</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-icon-zoom-in"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">i</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">span</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">span</span>
          <span class="hljs-attr">v-if</span>=<span class="hljs-string">"!disabled"</span>
          <span class="hljs-attr">class</span>=<span class="hljs-string">"el-upload-list__item-delete"</span>
          @<span class="hljs-attr">click</span>=<span class="hljs-string">"handleDownload(file)"</span>
        &gt;</span>
          <span class="hljs-tag">&lt;<span class="hljs-name">i</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-icon-download"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">i</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">span</span>&gt;</span>
        <span class="hljs-tag">&lt;<span class="hljs-name">span</span>
          <span class="hljs-attr">v-if</span>=<span class="hljs-string">"!disabled"</span>
          <span class="hljs-attr">class</span>=<span class="hljs-string">"el-upload-list__item-delete"</span>
          @<span class="hljs-attr">click</span>=<span class="hljs-string">"handleRemove(file)"</span>
        &gt;</span>
          <span class="hljs-tag">&lt;<span class="hljs-name">i</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-icon-delete"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">i</span>&gt;</span>
        <span class="hljs-tag">&lt;/<span class="hljs-name">span</span>&gt;</span>
      <span class="hljs-tag">&lt;/<span class="hljs-name">span</span>&gt;</span>
    <span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-upload</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">el-dialog</span> <span class="hljs-attr">:visible.sync</span>=<span class="hljs-string">"dialogVisible"</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">img</span> <span class="hljs-attr">width</span>=<span class="hljs-string">"100%"</span> <span class="hljs-attr">:src</span>=<span class="hljs-string">"dialogImageUrl"</span> <span class="hljs-attr">alt</span>=<span class="hljs-string">""</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">el-dialog</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">dialogImageUrl</span>: <span class="hljs-string">''</span>,
        <span class="hljs-attr">dialogVisible</span>: <span class="hljs-literal">false</span>,
        <span class="hljs-attr">disabled</span>: <span class="hljs-literal">false</span>
      };
    },
    <span class="hljs-attr">methods</span>: {
      handleRemove(file) {
        <span class="hljs-built_in">console</span>.log(file);
      },
      handlePictureCardPreview(file) {
        <span class="hljs-keyword">this</span>.dialogImageUrl = file.url;
        <span class="hljs-keyword">this</span>.dialogVisible = <span class="hljs-literal">true</span>;
      },
      handleDownload(file) {
        <span class="hljs-built_in">console</span>.log(file);
      }
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>图片列表缩略图</h3>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-4 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">ixu-upload</span>
  <span class="hljs-attr">class</span>=<span class="hljs-string">"upload-demo"</span>
  <span class="hljs-attr">action</span>=<span class="hljs-string">"https://jsonplaceholder.typicode.com/posts/"</span>
  <span class="hljs-attr">:on-preview</span>=<span class="hljs-string">"handlePreview"</span>
  <span class="hljs-attr">:on-remove</span>=<span class="hljs-string">"handleRemove"</span>
  <span class="hljs-attr">:file-list</span>=<span class="hljs-string">"fileList"</span>
  <span class="hljs-attr">list-type</span>=<span class="hljs-string">"picture"</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"small"</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span>&gt;</span>点击上传<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">slot</span>=<span class="hljs-string">"tip"</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-upload__tip"</span>&gt;</span>只能上传jpg/png文件，且不超过500kb<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-upload</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">fileList</span>: [{<span class="hljs-attr">name</span>: <span class="hljs-string">'food.jpeg'</span>, <span class="hljs-attr">url</span>: <span class="hljs-string">'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'</span>}, {<span class="hljs-attr">name</span>: <span class="hljs-string">'food2.jpeg'</span>, <span class="hljs-attr">url</span>: <span class="hljs-string">'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'</span>}]
      };
    },
    <span class="hljs-attr">methods</span>: {
      handleRemove(file, fileList) {
        <span class="hljs-built_in">console</span>.log(file, fileList);
      },
      handlePreview(file) {
        <span class="hljs-built_in">console</span>.log(file);
      }
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>上传文件列表控制</h3>
<p>通过 <code>on-change</code> 钩子函数来对列表进行控制</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-5 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">ixu-upload</span>
  <span class="hljs-attr">class</span>=<span class="hljs-string">"upload-demo"</span>
  <span class="hljs-attr">action</span>=<span class="hljs-string">"https://jsonplaceholder.typicode.com/posts/"</span>
  <span class="hljs-attr">:on-change</span>=<span class="hljs-string">"handleChange"</span>
  <span class="hljs-attr">:file-list</span>=<span class="hljs-string">"fileList"</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"small"</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span>&gt;</span>点击上传<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">slot</span>=<span class="hljs-string">"tip"</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-upload__tip"</span>&gt;</span>只能上传jpg/png文件，且不超过500kb<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-upload</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">fileList</span>: [{
          <span class="hljs-attr">name</span>: <span class="hljs-string">'food.jpeg'</span>,
          <span class="hljs-attr">url</span>: <span class="hljs-string">'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'</span>
        }, {
          <span class="hljs-attr">name</span>: <span class="hljs-string">'food2.jpeg'</span>,
          <span class="hljs-attr">url</span>: <span class="hljs-string">'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'</span>
        }]
      };
    },
    <span class="hljs-attr">methods</span>: {
      handleChange(file, fileList) {
        <span class="hljs-keyword">this</span>.fileList = fileList.slice(<span class="hljs-number">-3</span>);
      }
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>拖拽上传</h3>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-6 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">ixu-upload</span>
  <span class="hljs-attr">class</span>=<span class="hljs-string">"upload-demo"</span>
  <span class="hljs-attr">drag</span>
  <span class="hljs-attr">action</span>=<span class="hljs-string">"https://jsonplaceholder.typicode.com/posts/"</span>
  <span class="hljs-attr">multiple</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">i</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-icon-upload"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">i</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-upload__text"</span>&gt;</span>将文件拖到此处，或<span class="hljs-tag">&lt;<span class="hljs-name">em</span>&gt;</span>点击上传<span class="hljs-tag">&lt;/<span class="hljs-name">em</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-upload__tip"</span> <span class="hljs-attr">slot</span>=<span class="hljs-string">"tip"</span>&gt;</span>只能上传jpg/png文件，且不超过500kb<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-upload</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>手动上传</h3>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-7 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">ixu-upload</span>
  <span class="hljs-attr">class</span>=<span class="hljs-string">"upload-demo"</span>
  <span class="hljs-attr">ref</span>=<span class="hljs-string">"upload"</span>
  <span class="hljs-attr">action</span>=<span class="hljs-string">"https://jsonplaceholder.typicode.com/posts/"</span>
  <span class="hljs-attr">:on-preview</span>=<span class="hljs-string">"handlePreview"</span>
  <span class="hljs-attr">:on-remove</span>=<span class="hljs-string">"handleRemove"</span>
  <span class="hljs-attr">:file-list</span>=<span class="hljs-string">"fileList"</span>
  <span class="hljs-attr">:auto-upload</span>=<span class="hljs-string">"false"</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">slot</span>=<span class="hljs-string">"trigger"</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"small"</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"primary"</span>&gt;</span>选取文件<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">el-button</span> <span class="hljs-attr">style</span>=<span class="hljs-string">"margin-left: 10px;"</span> <span class="hljs-attr">size</span>=<span class="hljs-string">"small"</span> <span class="hljs-attr">type</span>=<span class="hljs-string">"success"</span> @<span class="hljs-attr">click</span>=<span class="hljs-string">"submitUpload"</span>&gt;</span>上传到服务器<span class="hljs-tag">&lt;/<span class="hljs-name">el-button</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">div</span> <span class="hljs-attr">slot</span>=<span class="hljs-string">"tip"</span> <span class="hljs-attr">class</span>=<span class="hljs-string">"el-upload__tip"</span>&gt;</span>只能上传jpg/png文件，且不超过500kb<span class="hljs-tag">&lt;/<span class="hljs-name">div</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">ixu-upload</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>
<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">fileList</span>: [{<span class="hljs-attr">name</span>: <span class="hljs-string">'food.jpeg'</span>, <span class="hljs-attr">url</span>: <span class="hljs-string">'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'</span>}, {<span class="hljs-attr">name</span>: <span class="hljs-string">'food2.jpeg'</span>, <span class="hljs-attr">url</span>: <span class="hljs-string">'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'</span>}]
      };
    },
    <span class="hljs-attr">methods</span>: {
      submitUpload() {
        <span class="hljs-keyword">this</span>.$refs.upload.submit();
      },
      handleRemove(file, fileList) {
        <span class="hljs-built_in">console</span>.log(file, fileList);
      },
      handlePreview(file) {
        <span class="hljs-built_in">console</span>.log(file);
      }
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>Attribute</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>action</td>
<td>必选参数，上传的地址</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>headers</td>
<td>设置上传的请求头部</td>
<td>object</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>multiple</td>
<td>是否支持多选文件</td>
<td>boolean</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>data</td>
<td>上传时附带的额外参数</td>
<td>object</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>name</td>
<td>上传的文件字段名</td>
<td>string</td>
<td>—</td>
<td>file</td>
</tr>
<tr>
<td>with-credentials</td>
<td>支持发送 cookie 凭证信息</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>show-file-list</td>
<td>是否显示已上传文件列表</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>drag</td>
<td>是否启用拖拽上传</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>accept</td>
<td>接受上传的<a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-accept">文件类型</a>（thumbnail-mode 模式下此参数无效）</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-preview</td>
<td>点击文件列表中已上传的文件时的钩子</td>
<td>function(file)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-remove</td>
<td>文件列表移除文件时的钩子</td>
<td>function(file, fileList)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-success</td>
<td>文件上传成功时的钩子</td>
<td>function(response, file, fileList)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-error</td>
<td>文件上传失败时的钩子</td>
<td>function(err, file, fileList)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-progress</td>
<td>文件上传时的钩子</td>
<td>function(event, file, fileList)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-change</td>
<td>文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用</td>
<td>function(file, fileList)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>before-upload</td>
<td>上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。</td>
<td>function(file)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>before-remove</td>
<td>删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。</td>
<td>function(file, fileList)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>list-type</td>
<td>文件列表的类型</td>
<td>string</td>
<td>text/picture/picture-card</td>
<td>text</td>
</tr>
<tr>
<td>auto-upload</td>
<td>是否在选取文件后立即进行上传</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>file-list</td>
<td>上传的文件列表, 例如: [{name: 'food.jpg', url: 'https://xxx.cdn.com/xxx.jpg'}]</td>
<td>array</td>
<td>—</td>
<td>[]</td>
</tr>
<tr>
<td>http-request</td>
<td>覆盖默认的上传行为，可以自定义上传的实现</td>
<td>function</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>limit</td>
<td>最大允许上传个数</td>
<td>number</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-exceed</td>
<td>文件超出个数限制时的钩子</td>
<td>function(files, fileList)</td>
<td>—</td>
<td>-</td>
</tr>
</tbody>
</table>
<h3>Slot</h3>
<table>
<thead>
<tr>
<th>name</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>trigger</td>
<td>触发文件选择框的内容</td>
</tr>
<tr>
<td>tip</td>
<td>提示说明文字</td>
</tr>
</tbody>
</table>
<h3>Methods</h3>
<table>
<thead>
<tr>
<th>方法名</th>
<th>说明</th>
<th>参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>clearFiles</td>
<td>清空已上传的文件列表（该方法不支持在 before-upload 中调用）</td>
<td>—</td>
</tr>
<tr>
<td>abort</td>
<td>取消上传请求</td>
<td>（ file: fileList 中的 file 对象 ）</td>
</tr>
<tr>
<td>submit</td>
<td>手动上传文件列表</td>
<td>—</td>
</tr>
</tbody>
</table>

          </div>
        </template>
        <script>
           //
//
//
//
//
//
//
//
//
//
//
//
//
//
// //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// //
//
//
//
//
//
//
//
//
//
//
// //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// //
//
//
//
//
//
//
//
//
//
//
// //
//
//
//
//
//
//
//
//
// //
//
//
//
//
//
//
//
//
//
//
//
//
//
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-upload',{staticClass:"upload-demo",attrs:{"action":"https://jsonplaceixu-upload--elholder.typicode.com/posts/","on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"before-remove":_vm.beforeRemove,"multiple":"","limit":3,"on-exceed":_vm.handleExceed,"file-list":_vm.fileList}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")]),_vm._v(" "),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("只能上传jpg/png文件，且不超过500kb")])],1)}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    }
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-1":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-upload',{staticClass:"avatar-uploader",attrs:{"action":"https://jsonplaceholder.typicode.com/posts/","show-file-list":false,"on-success":_vm.handleAvatarSuccess,"before-upload":_vm.beforeAvatarUpload}},[(_vm.imageUrl)?_c('img',{staticClass:"avatar",attrs:{"src":_vm.imageUrl}}):_c('i',{staticClass:"el-icon-plus avatar-uploader-icon"})])}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      imageUrl: ''
    };
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-2":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-upload',{attrs:{"action":"https://jsonplaceholder.typicode.com/posts/","list-type":"picture-card","on-preview":_vm.handlePictureCardPreview,"on-remove":_vm.handleRemove}},[_c('i',{staticClass:"el-icon-plus"})])}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    }
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-3":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-upload',{attrs:{"action":"#","list-type":"picture-card","auto-upload":false},scopedSlots:_vm._u([{key:"file",fn:function(ref){
var file = ref.file;
return _c('div',{},[_c('img',{staticClass:"el-upload-list__item-thumbnail",attrs:{"src":file.url,"alt":""}}),_vm._v(" "),_c('span',{staticClass:"el-upload-list__item-actions"},[_c('span',{staticClass:"el-upload-list__item-preview",on:{"click":function($event){return _vm.handlePictureCardPreview(file)}}},[_c('i',{staticClass:"el-icon-zoom-in"})]),_vm._v(" "),(!_vm.disabled)?_c('span',{staticClass:"el-upload-list__item-delete",on:{"click":function($event){return _vm.handleDownload(file)}}},[_c('i',{staticClass:"el-icon-download"})]):_vm._e(),_vm._v(" "),(!_vm.disabled)?_c('span',{staticClass:"el-upload-list__item-delete",on:{"click":function($event){return _vm.handleRemove(file)}}},[_c('i',{staticClass:"el-icon-delete"})]):_vm._e()])])}}])},[_c('i',{staticClass:"el-icon-plus",attrs:{"slot":"default"},slot:"default"})])}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false
    };
  },
  methods: {
    handleRemove(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleDownload(file) {
      console.log(file);
    }
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-4":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-upload',{staticClass:"upload-demo",attrs:{"action":"https://jsonplaceholder.typicode.com/posts/","on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"file-list":_vm.fileList,"list-type":"picture"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")]),_vm._v(" "),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("只能上传jpg/png文件，且不超过500kb")])],1)}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
    };
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    }
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-5":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-upload',{staticClass:"upload-demo",attrs:{"action":"https://jsonplaceholder.typicode.com/posts/","on-change":_vm.handleChange,"file-list":_vm.fileList}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")]),_vm._v(" "),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("只能上传jpg/png文件，且不超过500kb")])],1)}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      fileList: [{
        name: 'food.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }, {
        name: 'food2.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }]
    };
  },
  methods: {
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    }
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-6":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-upload',{staticClass:"upload-demo",attrs:{"drag":"","action":"https://jsonplaceholder.typicode.com/posts/","multiple":""}},[_c('i',{staticClass:"el-icon-upload"}),_vm._v(" "),_c('div',{staticClass:"el-upload__text"},[_vm._v("将文件拖到此处，或"),_c('em',[_vm._v("点击上传")])]),_vm._v(" "),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("只能上传jpg/png文件，且不超过500kb")])])}
var staticRenderFns = []

          const exportJavaScript = {};
           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-7":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-upload',{ref:"upload",staticClass:"upload-demo",attrs:{"action":"https://jsonplaceholder.typicode.com/posts/","on-preview":_vm.handlePreview,"on-remove":_vm.handleRemove,"file-list":_vm.fileList,"auto-upload":false}},[_c('el-button',{attrs:{"slot":"trigger","size":"small","type":"primary"},slot:"trigger"},[_vm._v("选取文件")]),_vm._v(" "),_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"size":"small","type":"success"},on:{"click":_vm.submitUpload}},[_vm._v("上传到服务器")]),_vm._v(" "),_c('div',{staticClass:"el-upload__tip",attrs:{"slot":"tip"},slot:"tip"},[_vm._v("只能上传jpg/png文件，且不超过500kb")])],1)}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      fileList: [{name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}, {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}]
    };
  },
  methods: {
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    }
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         












.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

       </style>